<template>
  <pages-index-intro />
  <div class="position-relative overflow-hidden">
    <pages-index-parallax />
    <pages-index-story :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-blog :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-services :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-meet-us :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-sections-lines />
  </div>
  <pages-index-recommendations />
  <pages-index-quote />
  <pages-index-faq />
</template>

<script lang="ts" setup>
import { getUrl } from '@/lib/helpers'

useSeoMeta({
  title: () => 'Psycho-Medical',
  ogTitle: () => 'Psycho-Medical',
  ogImage: () => getUrl('/opengraph/default.png'),
  ogUrl: () => getUrl(),
})
</script>
